import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';

import rootReducer from 'core/redux/reducers';
import rootSaga from 'core/redux/sagas';

import SocketManagerProvider from 'core/sockets/SocketManagerProvider';

import App from 'configs/routers';

import './index.css';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<SocketManagerProvider>
				<App />
			</SocketManagerProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);
