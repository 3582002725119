import React, { useRef, createContext } from 'react';
import { Manager } from 'socket.io-client';

import { SOCKET_NAMESPACES, OPTIONS } from 'app/constants/Realtime';
import { REALTIME_ENDPOINT } from 'configs/environments';

export const SocketContext = createContext({});

const SocketManagerProvider = ({ children }) => {
	const refManager = useRef(new Manager(REALTIME_ENDPOINT, OPTIONS));

	const value = {
		addonsSocket: refManager.current.socket(`/${SOCKET_NAMESPACES.ADDON}`),
		jobSocket: refManager.current.socket(`/${SOCKET_NAMESPACES.JOB}`),
	};

	return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketManagerProvider;
