export const SOCKET_EVENTS = {
	CONNECT: 'connect',
	DISCONNECT: 'disconnect',
	RECONNECTING: 'reconnecting',
	CONNECTED: 'connected',
	CONNECT_ERROR: 'connect_error',
	CONNECT_FAILED: 'connect_failed',
	ERROR: 'error',
};

export const SOCKET_NAMESPACES = {
	JOB: 'job',
	ADDON: 'addon',

	STATUS: 'status',
	EDIT: 'edit',
	DELETE: 'delete',
	MOVE: 'move',
	CREATE: 'create',
	RESIZE: 'resize',
	UPDATE: 'update',

	LOG_CREATE: 'log/create',
	INVOICE_STATUS: 'invoice/status',
	ESTIMATE_STATUS: 'estimate/status',
	SMS_CONVERSATION: 'sms/conversation',
	SMS_STATUS: 'sms/statusMessage',
	SMS_SEND: 'sms/sendMessage',
	SMS_RECEIVER: 'sms/receive',
	NOTIFY_COUNT: 'notification/countNote',
	CUSTOMER_BALANCE: 'customer/balance',
};

export const OPTIONS = {
	reconnection: true,
	reconnectionAttempts: 'Infinity',
	reconnectionDelay: 1000, // reconnect every 1m
	transports: ['websocket', 'polling'], // avoid blocking
	forceNew: false,
	jsonp: false,
	secure: true,
	rejectUnauthorized: false, // for https endpoint
	useSSL: false,
};
