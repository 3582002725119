import React, { useCallback, useEffect, Fragment, useRef, useReducer, useContext, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty, isObject } from '../../../core/utils/DefineUtils';

// full calendar & plugins
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';

// css
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/bootstrap/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'app/modules/calendar/styles/ItemCalendar.css';

// assets
import { LockIcon, RecurringIcon, WarningIcon, LoadingIcon, TimeWindowWarningIcon } from 'assets';

// actions
import {
	fetchDragEvent,
	fetchResizeEvent,
	stopFetchDragEvent,
	stopFetchResizeEvent,
	fetchJobList,
} from 'core/redux/actions/calendar/CalendarActions';
import { fetchUserProfile, setAccessToken } from 'core/redux/actions/profile/UserProfileActions';

import { SocketContext } from 'core/sockets/SocketManagerProvider';

// utils
import CheckMobilePlatform from 'core/utils/PlatformUtils';
import { isCheckPermission } from 'core/utils/PermissionUtils';
import { getDateRange } from 'core/utils/CalculatorDate';
import { reducer } from 'core/utils/ReducerUtils';

// constant
import {
	viewsConfig,
	TIME_GRID_WEEK,
	TIME_GRID_DAY,
	TIME_GRID_3_DAYS,
	TIME_GRID_4_DAYS,
	DAY_GRID_MONTH,
	TYPE_CLICK,
	MESSAGE_TYPES,
	TYPE_MOVE,
	AGENDA_MODES,
	AUTO_APPLY,
	JobStatusColor,
	TIME_OFF,
	APP_EDIT_JOB,
	MOVE_ADD_RECURRING,
	LONG_DAY_FORMAT,
	EVENT,
	EDIT_OR_DELETE_EVENT,
	EDIT_OR_DELETE_TIMEOFF,
	APP_ADD_JOB,
	ADD_EVENT,
	ADD_TIME_OFF,
	GD_BRANCH_ID,
} from 'app/constants/App';
import { SOCKET_EVENTS, SOCKET_NAMESPACES } from 'app/constants/Realtime';
const { STATUS, MOVE, DELETE, CREATE, RESIZE, UPDATE, SMS_RECEIVER, SMS_CONVERSATION } = SOCKET_NAMESPACES;

const cssModeMonth = {
	__html: `
		.fc-event-container {
		overflow-y: scroll;
		max-height: 200px;
		}
		`,
};

const isIOS = CheckMobilePlatform();

const initState = {
	events: [],
	dataEventResize: {},
	dataEventDrag: {},
	calendarDate: dayjs().format('YYYY-MM-DD'),
	calendarMode: TIME_GRID_DAY,
	zoom: 30,
	scheduleId: null,
	isWeekend: true,
	userProfile: {},
};

dayjs.extend(isBetween);

const Calendar = () => {
	const dispatch = useDispatch();

	const refCalendar = useRef();

	//#region get param URL
	const {
		location: { search },
	} = useHistory();
	const paramsUrl = new URLSearchParams(search);

	const token = paramsUrl.get('token');
	const room = paramsUrl.get('room');
	const initSchedule = paramsUrl.get('scheduleID');
	const autoApply = paramsUrl.get('modeApply');
	const heightSlotTime = paramsUrl.get('heightSlotTime')?.toString();
	const isWeekend = paramsUrl.get('weekends');
	const mode = paramsUrl.get('mode');
	const initDate = paramsUrl.get('initDate') || dayjs().format('YYYY-MM-DD');
	const branchId = paramsUrl.get('branchId');
	//#endregion

	const { addonsSocket: socketAddon, jobSocket: socketJob } = useContext(SocketContext);

	//#region deciare state
	const [state, dispatchState] = useReducer(reducer, initState);

	const { userProfile } = state;
	//#endregion

	const { permissions = [], unPermissions = [] } = userProfile || {};
	const isAllowEditJob = useMemo(
		() => isCheckPermission(APP_EDIT_JOB, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowMoveRecurring = useMemo(
		() => isCheckPermission(MOVE_ADD_RECURRING, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowEditTimeoff = useMemo(
		() => isCheckPermission(EDIT_OR_DELETE_TIMEOFF, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowEditEvent = useMemo(
		() => isCheckPermission(EDIT_OR_DELETE_EVENT, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowAddJob = useMemo(
		() => isCheckPermission(APP_ADD_JOB, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowAddTimeOff = useMemo(
		() => isCheckPermission(ADD_TIME_OFF, permissions, unPermissions),
		[permissions, unPermissions],
	);
	const isAllowAddCustomEvent = useMemo(
		() => isCheckPermission(ADD_EVENT, permissions, unPermissions),
		[permissions, unPermissions],
	);

	const CUSTOM_EVENT = [TIME_OFF, EVENT];

	const connectSocketAddon = () => {
		if (socketAddon?.connected) {
			socketAddon.emit(SOCKET_EVENTS.CONNECTED, parseInt(room));
		}
	};

	const connectSocketJob = () => {
		if (socketJob?.connected) {
			socketJob.emit(SOCKET_EVENTS.CONNECTED, parseInt(room));
		}
	};

	const _onSetSMSUnread = (customer_id, count = 0) => {
		if (!customer_id) return;

		dispatchState(oldState => {
			const oldEvents = oldState.events;
			const hasUnreadEvent = oldEvents.find(item => item.customer_id == customer_id && item.type === 'job');
			if (hasUnreadEvent) {
				const unread = parseInt(count) >= 1 ? 1 : 0;
				return {
					...oldState,
					events: oldEvents.map(item => {
						if (item.customer_id == customer_id && item.type === 'job') {
							item.circle_notify = unread;
							setExtendedProp(item.id, 'circle_notify', unread);
						}
						return item;
					}),
				};
			} else return oldState;
		});
	};

	const _onSocketStatusSms = response => {
		const conversations = response?.conversations || [];
		conversations.forEach(conversation => {
			_onSetSMSUnread(conversation.customer_id, conversation.unread);
		});
	};

	const _onReceiveMessage = response => {
		const customer = response?.customer || {};
		_onSetSMSUnread(customer.customer_id, customer.unread);
	};

	useEffect(() => {
		if (room && socketAddon && socketJob) {
			connectSocketAddon();
			connectSocketJob();

			socketJob.on(SOCKET_EVENTS.CONNECT, connectSocketJob);
			socketJob.on(MOVE, _onSocketMoveResizeEvent);
			socketJob.on(UPDATE, _onSocketMoveResizeEvent);
			socketJob.on(CREATE, _onSocketMoveResizeEvent);
			socketJob.on(RESIZE, _onSocketMoveResizeEvent);
			socketJob.on(DELETE, onSocketDeleteEvent);
			socketJob.on(STATUS, onSocketUpdateEventStatus);

			socketAddon.on(SOCKET_EVENTS.CONNECT, connectSocketAddon);
			socketAddon.on(SMS_CONVERSATION, _onSocketStatusSms);
			socketAddon.on(SMS_RECEIVER, _onReceiveMessage);

			return () => {
				socketJob.off(SOCKET_EVENTS.CONNECT, connectSocketJob);

				socketJob.off(MOVE, _onSocketMoveResizeEvent);
				socketJob.off(UPDATE, _onSocketMoveResizeEvent);
				socketJob.off(CREATE, _onSocketMoveResizeEvent);
				socketJob.off(RESIZE, _onSocketMoveResizeEvent);
				socketJob.off(DELETE, onSocketDeleteEvent);
				socketJob.off(STATUS, onSocketUpdateEventStatus);

				socketAddon.off(SOCKET_EVENTS.CONNECT, connectSocketAddon);
				socketAddon.off(SMS_CONVERSATION, _onSocketStatusSms);
				socketAddon.off(SMS_RECEIVER, _onReceiveMessage);
			};
		}
	}, [room]);

	const _fetchUserInfoSuccess = result => {
		dispatchState({
			userProfile: result,
		});
	};

	useEffect(() => {
		dispatch(setAccessToken(token));
		dispatchState({
			calendarDate: initDate,
			calendarMode: mode,
			zoom: heightSlotTime || 30,
			scheduleId: initSchedule,
			isWeekend: isWeekend,
		});

		dispatch(fetchUserProfile(token, _fetchUserInfoSuccess));

		_restoreTimeFramePosition();

		window.addEventListener('scroll', _saveTimeFramePosition, true);

		return () => {
			dispatch(stopFetchDragEvent());
			dispatch(stopFetchResizeEvent());

			window.removeEventListener('scroll', _saveTimeFramePosition, true);
			localStorage.removeItem(GD_BRANCH_ID);
		};
	}, []);

	useEffect(() => {
		localStorage.setItem(GD_BRANCH_ID, branchId);
	}, [branchId]);

	useEffect(() => {
		if (navigator.appVersion.includes('Android')) document.addEventListener('message', _onListeningMessageFromApp);
		else window.addEventListener('message', _onListeningMessageFromApp);

		return () => {
			if (navigator.appVersion.includes('Android'))
				document.removeEventListener('message', _onListeningMessageFromApp);
			else window.removeEventListener('message', _onListeningMessageFromApp);
		};
	}, [state.isWeekend, state.calendarDate, state.calendarMode, state.scheduleId]);

	useEffect(() => {
		_updateHeightTime();
	}, [state.zoom]);

	const onSocketUpdateEventStatus = response => {
		if (response?.eventId) {
			const { eventId, app, status, draft_invoice, editable, v2 } = response || {};
			const backgroundColor = app?.backgroundColor || JobStatusColor.get(status || '0');
			setProp(eventId, 'backgroundColor', backgroundColor);
			setExtendedProp(eventId, 'draft_invoice', draft_invoice);
			_updateEvent(eventId, {
				backgroundColor: backgroundColor,
				draft_invoice: draft_invoice,
				editable: editable,
				textColor: v2?.textColor,
			});
			if (response?.nextJob) {
				const nextJob = response?.nextJob;
				if (nextJob?.eventId) {
					const nextJobId = nextJob.eventId;
					const nextJobBackgroundColor = nextJob?.backgroundColor || JobStatusColor.get(status || '0');
					const nextJobEditable = nextJob?.editable ?? '0';

					setProp(nextJobId, 'textColor', '#000');
					_updateEvent(nextJobId, {
						backgroundColor: nextJobBackgroundColor,
						textColor: '#000',
						editable: nextJobEditable,
						type: 'job',
					});
				}
			}
		}
	};

	const _fetchJobList = async (date, mode, scheduleId) => {
		try {
			if (token) {
				let params = {
					schedule: scheduleId || state.scheduleId,
					params: {
						timeoff: 1,
					},
				};
				let timeDate;
				if (date && mode) {
					timeDate = getDateRange(mode, date);
				} else {
					timeDate = getDateRange(state.calendarMode, state.calendarDate);
				}
				if (timeDate) {
					params.start = timeDate.start;
					params.end = timeDate.end;
				}

				if (params.schedule) {
					dispatch(
						fetchJobList(
							params,
							token,
							results => {
								dispatchState({
									events: results.map(evt => {
										// lock event
										if (evt?.locked == '1' || evt?.previously_completed == '1') {
											evt = { ...evt, editable: false };
										} else if (evt.type === EVENT && !isAllowEditEvent) {
											evt = { ...evt, editable: false };
										} else if (evt.type === TIME_OFF && !isAllowEditTimeoff) {
											evt = { ...evt, editable: false };
										}
										return evt;
									}),
								});
								_emitPostMessage({ type: 'loading', value: false });
							},
							error => {
								_emitPostMessage({
									type: MESSAGE_TYPES.ERROR,
									loading: false,
									message: error || '',
								});
							},
						),
					);
				}
			}
		} catch (error) {
			_emitPostMessage({
				type: MESSAGE_TYPES.ERROR,
				loading: false,
				message: error.message ?? '',
			});
		}
	};

	const _onSocketMoveResizeEvent = response => {
		if (socketJob.id !== response.socket_id)
			dispatchState(oldState => {
				if (!response) return;

				const TIME_FORMAT = 'YYYY-MM-DD';

				const { start, end } = response.data || {};
				const timeStart = start?.substring(0, 10) || dayjs(start).format(TIME_FORMAT);
				const timeEnd = start?.substring(0, 10) || dayjs(end).format(TIME_FORMAT);
				const timeDate = getDateRange(
					oldState.calendarMode === DAY_GRID_MONTH ? DAY_GRID_MONTH : TIME_GRID_WEEK,
					oldState.calendarDate,
				);

				const isExistStart = dayjs(timeStart).isBetween(
					dayjs(timeDate.start, TIME_FORMAT).subtract(1, 'D'),
					dayjs(timeDate.end, TIME_FORMAT).add(1, 'D'),
					null,
					'[]',
				);

				const isExistEnd = dayjs(timeEnd).isBetween(
					dayjs(timeDate.start, TIME_FORMAT).subtract(1, 'D'),
					dayjs(timeDate.end, TIME_FORMAT).add(1, 'D'),
					null,
					'[]',
				);

				if (isExistStart || isExistEnd) {
					_fetchJobList(oldState.calendarDate, oldState.calendarMode, oldState.scheduleId);
				}

				return {
					...oldState,
				};
			});
	};

	const onSocketDeleteEvent = response => {
		if (!response?.jobs?.length) return;
		let events = [...(state.events || [])];

		const jobRemove = response.jobs || [];

		let isRefreshJobs = false;

		for (let i = 0; i < jobRemove.length; i++) {
			if (jobRemove[i]?.parent) {
				isRefreshJobs = true;
				break;
			}
			const index = events.findIndex(item => item.customer_job_id?.toString() === jobRemove[i]?.id?.toString());
			if (index !== -1) {
				events.splice(index, 1);
				const apiCalendar = refCalendar?.current?.getApi();
				const event = apiCalendar?.getEventById(jobRemove[i]?.id?.toString());

				if (event) event.remove();
			}
		}

		if (isRefreshJobs) {
			dispatchState(oldState => {
				_fetchJobList(oldState.calendarDate, oldState.calendarMode, oldState.scheduleId);
				return {
					...oldState,
				};
			});
		} else
			dispatchState({
				events: events,
			});
	};

	// update event tile
	const setProp = (id, name, value) => {
		const apiCalendar = refCalendar?.current?.getApi();
		const event = apiCalendar.getEventById(id);

		if (event) event.setProp(name, value);
	};

	const _emitPostMessage = data => {
		const convertString = JSON.stringify(data);
		if (convertString && typeof window.ReactNativeWebView !== 'undefined') {
			window.ReactNativeWebView.postMessage(convertString);
		}
	};

	// update an event by set state
	const _updateEvent = (id, props = {}) => {
		dispatchState(prevState => {
			const oldEvents = prevState.events;
			const index = oldEvents?.findIndex(evt => evt.id == id);
			if (index !== -1) {
				let _events = [...oldEvents];
				let replaceEvent = { ...oldEvents[index], ...props };
				if (props?.editable) {
					if (props?.editable == '0') replaceEvent = { ...replaceEvent, editable: false };
					else replaceEvent = { ...replaceEvent, editable: true };
				}
				_events[index] = replaceEvent;
				return {
					...prevState,
					events: _events,
				};
			} else
				return {
					...prevState,
					events: oldEvents,
				};
		});
	};

	const setExtendedProp = (id, name, value) => {
		const apiCalendar = refCalendar?.current?.getApi();
		const event = apiCalendar.getEventById(id);

		if (event) event.setExtendedProp(name, value);
	};

	// TIME OFF
	const _onAddTimeOff = event => {
		dispatchState(oldState => {
			const oldEvents = oldState.events ?? [];
			let newEvent = { ...event };
			if (event?.locked == '1' || event?.previously_completed == '1') newEvent = { ...event, editable: false };
			return {
				...oldState,
				events: [...oldEvents, newEvent],
			};
		});
	};

	const _onDeleteTimeOff = idTimeOff => {
		dispatchState(oldState => {
			const oldEvents = oldState.events ?? [];
			const indexEvent = oldEvents.findIndex(evt => evt.customer_job_id == idTimeOff);
			if (indexEvent !== -1) {
				return {
					...oldState,
					events: oldEvents.filter(evt => evt.customer_job_id != idTimeOff),
				};
			} else {
				return oldState;
			}
		});
	};

	const _onUnSelectDate = () => {
		dispatchState(oldState => {
			const oldEvents = oldState.events ?? [];
			const indexVirtualEvent = oldEvents.findIndex(evt => evt.id == -1);
			if (indexVirtualEvent !== -1) {
				return {
					...oldState,
					events: oldEvents.filter(evt => evt.id != -1),
				};
			} else {
				return oldState;
			}
		});
	};

	const _onListeningMessageFromApp = message => {
		if (isObject(message?.data)) return;
		try {
			const apiCalendar = refCalendar?.current?.getApi();
			const data = JSON.parse(message?.data);

			switch (data.type) {
				case MESSAGE_TYPES.REFRESH: {
					_fetchJobList(data?.startDate, data?.mode, data.scheduleId);
					break;
				}

				case MESSAGE_TYPES.ADD_EVENT:
					_onAddTimeOff(data.data);
					break;

				case MESSAGE_TYPES.UN_SELECTDATE:
					apiCalendar.unselect();
					_onUnSelectDate();
					if (apiCalendar?.view?.type === DAY_GRID_MONTH) {
						let el = document.querySelector('td.fc-day[selected]');
						if (el) {
							el.style.backgroundColor = '#FFF';
							el.removeAttribute('selected');
						}
					}
					break;

				case MESSAGE_TYPES.SCROLL_TO_TIME:
					_scrollToCurrentHour();
					break;

				case MESSAGE_TYPES.UPDATE_RECURR_JOB:
					_handleUpdateEventFromApp(data?.click_type);
					refCalendar.current?.getApi()?.unselect();
					break;

				case MESSAGE_TYPES.DELETE_TIME_OFF:
					_onDeleteTimeOff(data?.idTimeOff);
					break;

				case MESSAGE_TYPES.VIEW:
					_onChangeView(data?.mode, data?.startDate);
					break;

				case MESSAGE_TYPES.ZOOM:
					dispatchState({
						zoom: data.zoom || 30,
					});
					break;

				case MESSAGE_TYPES.SCHEDULE:
					dispatchState({
						scheduleId: data.scheduleId ?? null,
					});
					break;
				case MESSAGE_TYPES.TOGGLE_WEEKENDS:
					dispatchState({
						isWeekend: data?.showWeekends ?? true,
					});
					break;

				default:
					_emitPostMessage({ type: 'loading', value: false });
					break;
			}
		} catch (error) {
			_emitPostMessage({ type: 'loading', value: false });
		}
	};

	const _onChangeView = (_mode = mode, _startDate) => {
		if (_mode === DAY_GRID_MONTH) dispatchState({ events: [] });
		if (_mode === TIME_GRID_DAY || _mode === TIME_GRID_3_DAYS || _mode === TIME_GRID_4_DAYS)
			dispatchState({ isWeekend: true });
		const apiCalendar = refCalendar?.current?.getApi();
		apiCalendar?.changeView(_mode, _startDate);
		dispatchState({
			calendarDate: _startDate,
			calendarMode: _mode,
		});
	};

	useEffect(() => {
		_fetchJobList();
	}, [state.scheduleId, state.calendarDate, state.calendarMode]);

	// SELECT TIME EVENTS
	const _onSelectTime = useCallback(
		async infoTime => {
			const apiCalendar = refCalendar?.current?.getApi();

			const indexVirtualEvent = state.events.findIndex(event => event.id === -1);
			const virtualEvent = {
				id: -1,
				allDay: false,
				start: infoTime?.start,
				startStr: infoTime?.startStr,
				end: infoTime?.end,
				endStr: infoTime?.endStr,
				backgroundColor: '#FFF',
				borderColor: 'rgba(142, 74, 222, 1)',
			};

			if (indexVirtualEvent !== -1) {
				apiCalendar.unselect();

				if (state.calendarMode !== DAY_GRID_MONTH) {
					let newEvent = [...state.events];
					newEvent[indexVirtualEvent] = virtualEvent;
					dispatchState({
						events: newEvent,
					});
				}

				_emitPostMessage({
					type_click: TYPE_CLICK.ADD_JOB,
					data: `${infoTime?.startStr}`,
					dateStar: `${infoTime?.startStr}`,
					dateEnd: `${infoTime?.endStr}`,
				});
			} else {
				apiCalendar.unselect();
				if (state.calendarMode !== DAY_GRID_MONTH)
					dispatchState({
						events: [...state.events, virtualEvent],
					});
				_emitPostMessage({
					type_click: TYPE_CLICK.ADD_JOB,
					data: `${infoTime?.startStr}`,
					dateStar: `${infoTime?.startStr}`,
					dateEnd: `${infoTime?.endStr}`,
				});
			}
		},
		[state.calendarMode, state.events],
	);

	const _onDateClick = infoDate => {
		const dateStr = infoDate.date;

		if (state.calendarMode === DAY_GRID_MONTH) {
			if (infoDate.jsEvent.target.className === 'fc-day-number') {
				_emitPostMessage({
					type_click: TYPE_CLICK.DATE,
					data: dateStr,
				});
				_onChangeView(TIME_GRID_DAY, dayjs(dateStr).format('YYYY-MM-DD'));
			} else {
				_onUnSelectDate();

				// unselect
				let el = document.querySelector('td.fc-day[selected]');
				if (el) {
					el.style.backgroundColor = 'white';
					el.removeAttribute('selected');
				}

				// reselect
				infoDate.dayEl.style.backgroundColor = 'rgba(142, 96, 242, 0.3)';
				infoDate.dayEl.setAttribute('selected', 'selected');

				_onSelectTime({
					start: infoDate.date,
					startStr: dateStr,
					end: dayjs(infoDate.date).add(12, 'h'),
					endStr: dayjs(infoDate.date).add(12, 'h').toString(),
				});
			}
		} else {
			const indexVirtualEvent = state.events.findIndex(event => event.id === -1);

			if (indexVirtualEvent !== -1) {
				if (infoDate.dateStr !== state.events[indexVirtualEvent]?.startStr) {
					refCalendar.current?.getApi().unselect();
					// setEvents(events.filter(event => event.id !== -1));
					dispatchState({
						events: state.events.filter(event => event.id !== -1),
					});
					_emitPostMessage({
						type_click: 5,
					});
				}
			}
		}
	};

	const _onEventClick = ({ event, el }) => {
		const backup_border_style = el.style.border;
		el.style.border = '2px solid rgb(142, 74, 222)';
		setTimeout(() => {
			el.style.border = backup_border_style;
		}, 500);

		const data = {
			type_click: TYPE_CLICK.EVENT,
			data: event.extendedProps,
		};

		_emitPostMessage(data);
	};

	const _onEventChange = infoEvent => {
		// dispatch(setIsDisableSwipe(false));
		const { id, startStr, endStr } = infoEvent?.event || {};
		if (id == -1) {
			_emitPostMessage({
				type_click: TYPE_CLICK.ADD_JOB,
				data: startStr,
				dateStar: startStr,
				dateEnd: endStr,
			});
		}
	};

	const _handleUpdateEventFromApp = type => {
		dispatchState(oldState => {
			const oldEvents = oldState.events ?? [];
			const oldDataDrag = oldState.dataEventDrag ?? {};
			const oldDataResize = oldState.dataEventResize ?? {};
			let indexEvent = -1;
			if (!isEmpty(oldDataDrag)) {
				indexEvent = oldEvents.findIndex(evt => evt.id == oldDataDrag?.event?.id);
				if (indexEvent !== -1) {
					let newDataDrag = {};
					switch (type) {
						case TYPE_MOVE.ONLY:
							_handleDragEvent(oldDataDrag, AUTO_APPLY.ONLY);
							newDataDrag = oldDataDrag;
							break;

						case TYPE_MOVE.ALL:
							_handleDragEvent(oldDataDrag, AUTO_APPLY.ALL);
							newDataDrag = oldDataDrag;
							break;

						case TYPE_MOVE.CANCEL:
							oldDataDrag.revert();
							setTimeout(() => {
								newDataDrag = {};
							}, 200);
							break;

						default:
							newDataDrag = oldDataDrag;
							break;
					}
					return {
						...oldState,
						dataEventDrag: newDataDrag,
					};
				} else return oldState;
			} else if (!isEmpty(oldDataResize)) {
				indexEvent = oldEvents.findIndex(evt => evt.id == oldDataResize?.event?.id);
				if (indexEvent !== -1) {
					let newDataResize = {};
					switch (type) {
						case TYPE_MOVE.ONLY:
							_handleResizeEvent(oldDataResize, AUTO_APPLY.ONLY);
							newDataResize = oldDataResize;
							break;

						case TYPE_MOVE.ALL:
							_handleResizeEvent(oldDataResize, AUTO_APPLY.ALL);
							newDataResize = oldDataResize;
							break;

						case TYPE_MOVE.CANCEL:
							oldDataResize.revert();
							setTimeout(() => {
								newDataResize = {};
							}, 200);
							break;

						default:
							newDataResize = oldDataResize;
							break;
					}
					return {
						...oldState,
						dataEventResize: newDataResize,
					};
				} else return oldState;
			}

			return oldState;
		});
	};

	const _toggleEventLoading = (idEvent, isLoading = false) => {
		let el = document.querySelector(`[loading-id="${idEvent}"]`);
		if (isLoading) el.style.display = 'block';
		else el.style.display = 'none';
	};

	// DRAG EVENT
	const _onFetchDragEventSuccessed = (idEvent, modeApply, typeEvent) => {
		refCalendar.current?.getApi()?.unselect();
		_toggleEventLoading(idEvent);
		_emitPostMessage({ type: MESSAGE_TYPES.SHOW_LOADING, loading: false });
		_fetchJobList(state.calendarDate, state.calendarMode, state.scheduleId);
		dispatchState({ dataEventDrag: {} });
		if (typeEvent === TIME_OFF) {
			_emitPostMessage({
				type: MESSAGE_TYPES.SHOW_LOADING,
				loading: true,
			});
		}
	};

	const _onFetchDragEventFailed = (idEvent, message) => {
		refCalendar.current?.getApi()?.unselect();
		_toggleEventLoading(idEvent);

		dispatchState(oldState => {
			const oldDataDrag = oldState.dataEventDrag ?? {};
			if (!isEmpty(oldDataDrag)) oldDataDrag.revert();
			return {
				...oldState,
				dataEventDrag: {},
			};
		});
		_emitPostMessage({
			type: MESSAGE_TYPES.ERROR,
			loading: false,
			message: message || 'Drag event failed!',
		});
	};

	function _removeEventsDuplicate(id) {
		const calendarEvents = refCalendar.current?.getApi()?.getEvents();
		let duplicatedEvents = calendarEvents.filter(e => e.id === id);

		if (duplicatedEvents.length) {
			duplicatedEvents.splice(-1, 1); // keep the last one
			duplicatedEvents.map(evt => evt.remove()); // remove the others, may be more than one
		}
	}

	// MOVE EVENT
	const _handleDragEvent = (infoEvent, modeApply = autoApply) => {
		if (!isEmpty(infoEvent?.event)) {
			const { id, start, extendedProps } = infoEvent.event || {};
			_removeEventsDuplicate(id);
			if (!isEmpty(extendedProps)) {
				let params;
				// move job, v1 API
				if (extendedProps.type !== TIME_OFF && extendedProps.type !== EVENT) {
					const startDate = dayjs(start).format('YYYY-MM-DD');
					const startTime = dayjs(start).format('HH:mm');
					params = {
						id: id,
						start_date: startDate,
						start_time: startTime,
						moveall: modeApply,
						moveToSchedule: state.scheduleId,
						agenda: AGENDA_MODES[state.calendarMode],
						socket_id: socketJob.id,
					};
				}
				// move event, timeoff, v2 API
				else {
					const startDate = dayjs(start).format(LONG_DAY_FORMAT);
					params = {
						start: dayjs.tz(startDate, LONG_DAY_FORMAT, 'UTC').toISOString(),
						all: modeApply == AUTO_APPLY.ALL ? 1 : 0,
						schedule: state.scheduleId,
						agenda: AGENDA_MODES[state.calendarMode],
						socket_id: socketJob.id,
					};
				}
				dispatch(
					fetchDragEvent(id, extendedProps.type, params, _onFetchDragEventSuccessed, _onFetchDragEventFailed),
				);
				_emitPostMessage({
					type: MESSAGE_TYPES.SHOW_LOADING,
					loading: true,
				});
			}
		}
	};

	const _onEventDrag = infoEvent => {
		if (!isEmpty(infoEvent?.event)) {
			const { id, start, end, extendedProps } = infoEvent?.event || {};
			if (id == -1) {
				const startTime = dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ');
				const endTime = dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ');
				_emitPostMessage({
					type_click: TYPE_CLICK.ADD_JOB,
					data: startTime,
					dateStar: startTime,
					dateEnd: endTime,
				});
			} else {
				if (!isEmpty(extendedProps)) {
					const isRecurring = CUSTOM_EVENT.includes(extendedProps.type)
						? extendedProps.recurring == '1'
						: extendedProps.repeat == '1';
					if (autoApply != AUTO_APPLY.NULL || !isRecurring || !isAllowMoveRecurring) {
						_handleDragEvent(infoEvent, !isAllowMoveRecurring ? AUTO_APPLY.ONLY : autoApply);
					} else {
						_emitPostMessage({ cover: true, editType: 'move', eventType: extendedProps?.type });
					}
					dispatchState({
						dataEventDrag: { ...infoEvent },
					});
					_toggleEventLoading(id, true);
				}
			}
		}
	};

	const _onFetchResizeEventSuccessed = (idEvent, modeApply, typeEvent) => {
		refCalendar.current?.getApi()?.unselect();
		_toggleEventLoading(idEvent);
		_emitPostMessage({ type: MESSAGE_TYPES.SHOW_LOADING, loading: false });
		dispatchState(oldState => {
			return { ...oldState, dataEventResize: {} };
		});
		_fetchJobList(state.calendarDate, state.calendarMode, state.scheduleId);
		if (typeEvent === TIME_OFF) {
			_emitPostMessage({
				type: MESSAGE_TYPES.SHOW_LOADING,
				loading: true,
			});
		}
	};

	const _onFetchResizeEventFailed = (idEvent, message) => {
		refCalendar.current?.getApi()?.unselect();
		_toggleEventLoading(idEvent);

		dispatchState(oldState => {
			const oldDataResize = oldState.dataEventResize ?? {};
			if (!isEmpty(oldDataResize)) oldDataResize.revert();
			return {
				...oldState,
				dataEventResize: {},
			};
		});
		_emitPostMessage({
			type: MESSAGE_TYPES.ERROR,
			loading: false,
			message: message || 'Resize event failed!',
		});
	};

	// RESIZE EVENT
	const _handleResizeEvent = (infoEvent, modeApply = autoApply) => {
		if (!isEmpty(infoEvent?.event)) {
			const { id, end, extendedProps } = infoEvent.event || {};
			_removeEventsDuplicate(id);
			if (!isEmpty(extendedProps)) {
				let params;
				// resize job, v1 API
				if (extendedProps.type !== TIME_OFF && extendedProps.type !== EVENT) {
					const startTime = dayjs(infoEvent?.prevEvent?.end).format('HH:mm');
					const endTime = dayjs(end).format('HH:mm');
					params = {
						id: id,
						start_time: startTime,
						end_time: endTime,
						moveall: modeApply,
						moveToSchedule: state.scheduleId,
						agenda: AGENDA_MODES[state.calendarMode],
						socket_id: socketJob.id,
					};
				}
				// resize event, timeoff, v1 API
				else {
					const oldEndTime = dayjs(infoEvent?.prevEvent?.end).format(LONG_DAY_FORMAT);
					const endTime = dayjs(end).format(LONG_DAY_FORMAT);
					params = {
						from: dayjs.tz(oldEndTime, LONG_DAY_FORMAT, 'UTC').toISOString(), // old end time
						to: dayjs.tz(endTime, LONG_DAY_FORMAT, 'UTC').toISOString(), // new end time
						all: modeApply == AUTO_APPLY.ALL ? 1 : 0,
						agenda: AGENDA_MODES[state.calendarMode],
						schedule: state.scheduleId,
						socket_id: socketJob.id,
					};
				}
				dispatch(
					fetchResizeEvent(
						id,
						extendedProps.type,
						params,
						_onFetchResizeEventSuccessed,
						_onFetchResizeEventFailed,
					),
				);
				_emitPostMessage({
					type: MESSAGE_TYPES.SHOW_LOADING,
					loading: true,
				});
			}
		}
	};

	const _onEventResize = infoEvent => {
		if (!isEmpty(infoEvent?.event)) {
			const { id, start, end, extendedProps } = infoEvent?.event || {};

			if (id == -1) {
				const startTime = dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ');
				const endTime = dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ');
				_emitPostMessage({
					type_click: TYPE_CLICK.ADD_JOB,
					data: startTime,
					dateStar: startTime,
					dateEnd: endTime,
				});
			} else {
				if (!isEmpty(extendedProps)) {
					const isRecurring = CUSTOM_EVENT.includes(extendedProps.type)
						? extendedProps.recurring == '1'
						: extendedProps.repeat == '1';
					if (autoApply != AUTO_APPLY.NULL || !isRecurring || !isAllowMoveRecurring) {
						_handleResizeEvent(infoEvent);
					} else {
						_emitPostMessage({ cover: true, editType: 'resize', eventType: extendedProps?.type });
					}
					dispatchState({
						dataEventResize: { ...infoEvent },
					});
					_toggleEventLoading(id, true);
				}
			}
		}
	};

	// EVENT CONTENT
	const _handleEventContent = useCallback(
		(content = '') => {
			let result = [];
			const title_classname = state.calendarMode === TIME_GRID_DAY ? 'fc-title-2' : 'fc-title';
			content?.split('\n')?.forEach(str => {
				result.push(
					<div
						key={str}
						className={title_classname}>
						{str?.trim()}
					</div>,
				);
			});
			return result;
		},
		[state.calendarMode],
	);

	// EVENT ICON
	const _renderLoadingIcon = useCallback(eventId => {
		return (
			<div
				className='loading-icon'
				loading-id={eventId}>
				{LoadingIcon()}
			</div>
		);
	}, []);

	const _renderWarningIcon = useCallback(
		locked => {
			const warning_classname =
				locked == 0
					? 'warning-block'
					: state.calendarMode == DAY_GRID_MONTH
					? 'warning-block warning-bottom'
					: 'warning-block warning-left';
			return <div className={warning_classname}>{WarningIcon}</div>;
		},
		[state.calendarMode],
	);

	const _renderLockIcon = useCallback(hasNotify => {
		return <div className={hasNotify ? 'lock-block-padding' : 'lock-block'}>{LockIcon}</div>;
	}, []);

	const _renderRecurrIcon = useCallback(() => {
		const recurr_classname = state.calendarMode === DAY_GRID_MONTH ? 'recurr-block-month' : 'recurr-block';
		return <div className={recurr_classname}>{RecurringIcon}</div>;
	}, [state.calendarMode]);

	const _renderTimeWindowWarningIcon = () => {
		const tw_classname =
			state.calendarMode === DAY_GRID_MONTH ? 'time-window-warning-month' : 'time-window-warning';
		return <div className={tw_classname}>{TimeWindowWarningIcon}</div>;
	};

	const _renderUnreadSMSDot = () => {
		return <div className='unread-sms'></div>;
	};

	function _updateHeightTime() {
		const el = document.querySelectorAll('.fc-time-grid .fc-slats td');
		for (let j = 0; j < el.length; j++) {
			el.item(j).style.height = `${state.zoom}px`;
		}
	}

	const _handleEventRender = useCallback(
		({ event, el }) => {
			_updateHeightTime();
			if (isIOS) _alternateEventClick(event, el);

			if (event.id == -1) el.setAttribute('event-id', '-1');

			const extended = event.extendedProps;
			const isLocked = extended.locked == '1';
			const isJobType = extended.type === 'job';
			const isRecurringJobType = extended.type === 'recurr_job';
			const isUnreadSMS = extended?.circle_notify > 0;

			// class name
			let title_classname = 'fc-title';
			if (state.calendarMode === TIME_GRID_DAY) title_classname = 'fc-title-2';
			if (state.calendarMode === DAY_GRID_MONTH) title_classname = 'fc-title fc-title-month';

			// time window match
			const match = extended?.time_window?.match;

			const element = (
				<div className='fc-content'>
					{extended.draft_invoice == 1 && _renderWarningIcon(extended.locked || (isUnreadSMS && isJobType))}
					{isLocked && _renderLockIcon(isUnreadSMS && isJobType)}
					{isRecurringJobType && match === 1 && _renderRecurrIcon()}
					{match === 0 && _renderTimeWindowWarningIcon()}
					{isJobType && isUnreadSMS && _renderUnreadSMSDot()}
					<div className={title_classname}>{event.title}</div>
					{_renderLoadingIcon(event.id)}
					{extended?.event_content?.length > 0 && _handleEventContent(extended?.event_content)}
				</div>
			);

			el.querySelector('.fc-content').innerHTML = renderToStaticMarkup(element);

			return el;
		},
		[state.calendarMode, state.zoom, _updateHeightTime],
	);

	// save current time frames & restore scroll bar
	const _scrollToCurrentHour = (current_hour = dayjs().format('HH')) => {
		if (state.calendarMode === DAY_GRID_MONTH) return;

		let el = document.querySelector('.fc-scroller');
		if (el) el.scrollTop = current_hour * (parseInt(state.zoom) * 4) - window.innerHeight / 3;
	};

	const _saveTimeFramePosition = () => {
		let el = document.querySelector('.fc-scroller');
		if (el) {
			const pos = el?.scrollTop;
			sessionStorage.setItem('scroll', pos);
		}
	};

	const _restoreTimeFramePosition = () => {
		const pos = sessionStorage.getItem('scroll');

		let el = document.querySelector('.fc-scroller');
		if (Number(pos) > 0 && el) el.scrollTop = pos;
		else _scrollToCurrentHour();
	};

	const _alternateEventClick = (evt, el) => {
		if (evt?.id == -1) return; // except mirror

		let longpress = false;
		let pressTimer = null;

		el.onclick = event => {
			event.stopPropagation();
			event.stopImmediatePropagation();

			if (longpress) return false;
			else _onEventClick({ event: evt, el });
		};

		el.onmousedown = () => {
			pressTimer = window.setTimeout(function () {
				longpress = true;
			}, 500);
		};

		el.onmouseup = () => {
			clearTimeout(pressTimer);
		};
	};

	const _viewSkeletonRender = ({ view }) => {
		_emitPostMessage({ type: MESSAGE_TYPES.VIEW, mode: view.type });
	};

	const _selectConstraint = {
		startTime: '00:00',
		endTime: '23:55',
	};

	return (
		<Fragment>
			{state.calendarMode === DAY_GRID_MONTH && <style dangerouslySetInnerHTML={cssModeMonth} />}
			<div
				id={`calendar-${initDate}`}
				className={'containerFullcalendar'}>
				<FullCalendar
					key={'calendar_' + initDate}
					ref={refCalendar}
					allDaySlot={false}
					defaultDate={initDate}
					defaultView={mode}
					header={false}
					columnHeader={state.calendarMode === DAY_GRID_MONTH}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
					events={state.events}
					eventRender={_handleEventRender}
					eventConstraint={_selectConstraint}
					views={viewsConfig}
					editable={isAllowEditJob}
					droppable={false}
					lazyFetching
					selectMirror
					height={'parent'} // auto use webview vh for resize, avoid using listener
					themeSystem='bootstrap'
					nowIndicator
					selectable={isAllowAddJob || isAllowAddTimeOff || isAllowAddCustomEvent}
					selectConstraint={_selectConstraint}
					unselectAuto
					select={_onSelectTime}
					selectHelper
					dateClick={_onDateClick}
					eventClick={_onEventClick}
					displayEventTime={false}
					dayCount={1}
					eventDrop={_onEventDrag}
					eventResize={_onEventResize}
					eventChange={_onEventChange}
					eventLimit
					eventLimitText={'Jobs'}
					slotDuration={'00:15:00'}
					snapDuration={'00:15:00'}
					slotLabelInterval={'01:00'}
					slotEventOverlap={false}
					selectLongPressDelay={500} // delay long press datetime
					eventLongPressDelay={800} // delay long press event
					dayMaxEventRows
					// save current position & scroll when change date
					datesRender={_restoreTimeFramePosition}
					datesDestroy={_saveTimeFramePosition}
					dayRender={_updateHeightTime}
					weekends={
						state.calendarMode == TIME_GRID_WEEK || state.calendarMode == DAY_GRID_MONTH
							? state.isWeekend
							: true
					}
					viewSkeletonRender={_viewSkeletonRender}
				/>
			</div>
		</Fragment>
	);
};

export default Calendar;
