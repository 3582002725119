import React from 'react';

const WarningIcon = (
	<svg
		width='18'
		height='16'
		viewBox='0 0 18 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.1651 1.75C10.2028 0.0833318 7.79718 0.083336 6.83494 1.75L1.63878 10.75C0.676533 12.4167 1.87935 14.5 3.80385 14.5H14.1962C16.1207 14.5 17.3235 12.4167 16.3612 10.75L11.1651 1.75Z'
			fill='#EE4646'
			stroke='white'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M9 11C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11Z'
			fill='white'
		/>
		<rect
			x='8'
			y='3'
			width='2'
			height='6'
			rx='1'
			fill='white'
		/>
	</svg>
);
export default WarningIcon;
